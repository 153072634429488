import axios from 'axios';
import { message } from 'ant-design-vue';
import router from '@/router'
import Qs from 'qs'  //引入qs  时axios的自带模块


//let baseURL = "http://192.168.1.61:8082/"
//let baseURL = "http://120.27.226.233"
let baseURL = "https://m.track.hao3you.com"
//let baseURL = "http://track.hao3you.com"
//let baseURL = "http://iwv9fe.natappfree.cc"


if (process.env.NODE_ENV == 'development') {
  //baseURL = ''
} else if (process.env.VUE_APP_CURRENTMODE == "test") {
  //baseURL = 'http://api.carbonweb.cn/'
} else if (process.env.VUE_APP_CURRENTMODE == 'production') {
  // baseURL = 'http://api.carbonweb.cn/'
}

message.config({
  top: `300px`,
  duration: 2,
  maxCount: 1
});

const instance = axios.create({    //创建axios实例，在这里可以设置请求的默认配置
  timeout: 10000, // 设置超时时间10s
  baseURL: baseURL   //根据自己配置的反向代理去设置不同环境的basUrl
})
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
let token = localStorage.getItem("token") ? localStorage.getItem("token") : ''
console.log("http::", token)
instance.defaults.headers = {
  authorization: token,
  'Content-Type': 'application/x-www-form-urlencoded'
}



let httpCode = {        //这里我简单列出一些常见的http状态码信息，可以自己去调整配置
  400: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时'
}

/** 添加请求拦截器 **/
instance.interceptors.request.use(config => {
  config.headers['authorization'] = localStorage.getItem('token') ? localStorage.getItem('token') : ''

  if (config.method === 'get') { // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
    console.log("get:::config", config)
    config.params = {
      ...config.params,
      t: new Date().getTime()
    }
  }
  if (config.method === 'post') { // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
    console.log("get:::config", config)
    config.params = {
      ...config.params,
      t: new Date().getTime()
    }
  }
  // 在这里：可以根据业务需求可以在发送请求之前做些什么:例如我这个是导出文件的接口，因为返回的是二进制流，所以需要设置请求响应类型为blob，就可以在此处设置。
  if (config.url.includes('pur/contract/export')) {
    config.headers['responseType'] = 'blob'
  }
  // 我这里是文件上传，发送的是二进制流，所以需要设置请求头的'Content-Type'
  if (config.url.includes('pur/contract/upload')) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }
  console.log("config::", config)
  return config
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error)
})

/** 添加响应拦截器  **/
instance.interceptors.response.use(response => {
  console.log("响应拦截器：")
  console.log(response)
  if (response.status == 200 && response.data.code == -1) {
    let num = Math.floor(Math.random()*1000000);
    console.log("num::",num)
    router.push("/index?showLogin="+num)
    return Promise.reject(response.data.message)
  } else if (response.status == 200 && response.data.code == 0) {     // 响应结果里的我与后台的约定，大家可以根据实际情况去做对应的判断
    
    message.error(response.data.message)
    return Promise.reject(response.data.essage)
  } else if (response.status == 200) {     // 响应结果里的我与后台的约定，大家可以根据实际情况去做对应的判断
    return Promise.resolve(response.data)
  }
}, error => {
  if (error.response) {
    // 根据请求失败的http状态码去给用户相应的提示
    let tips = error.response.status in httpCode ? httpCode[error.response.status] : error.response.data.message
    message({
      message: tips,
      type: 'error'
    })
    if (error.response.status === 401) {    // token或者登陆失效情况下跳转到登录页面，根据实际情况，在这里可以根据不同的响应错误结果，做对应的事。这里我以401判断为例
      router.push({
        path: `/login`
      })
    }
    return Promise.reject(error)
  } else {
    message({
      message: '请求超时, 请刷新重试',
      type: 'error'
    })
    return Promise.reject(new Error('请求超时, 请刷新重试'))
  }
})

/* 统一封装get请求 */
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url,
      params,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/* 统一封装post请求  */
export const post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url,
      data: Qs.stringify(data),
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/* 或者写成下面这样： Promise.resolve() 和 Promise.reject()返回的是promise对象，二者都是语法糖  */
/*
export const post = (url, data, config = {}) => {
  return instance({
    method: 'post',
    url,
    data,
    ...config
  }).then(response => {
    return Promise.resolve(response)
  }).catch(error => {
    return Promise.reject(error)
  })
}
*/