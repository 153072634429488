import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./css/reset.css"


import '../node_modules/amfe-flexible/index.js'


import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

/** 
import Router from 'vue-router'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
router.beforeEach((to, from, next) => {
  console.log("to::",to)
  let banner;
  if(to.meta.banner){
    banner = 1
  }else{
    banner =0
  }
  Vue.prototype.banner = banner
  document.documentElement.scrollTop = 0
  if (to.meta &&to.meta.title) {
   // document.title = to.meta.title
  }
 next()
})
*/

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
