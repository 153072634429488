import axios from "axios"
import QS from "qs"
import router from '@/router'
const showStatus = (status) => {
    let message = ''
    switch (status) {
      case 400:
        message = '请求错误(400)'
        break
      case 401:
        message = '未授权，请重新登录(401)'
        break
      case 403:
        message = '拒绝访问(403)'
        break
      case 404:
        message = '请求出错(404)'
        break
      case 408:
        message = '请求超时(408)'
        break
      case 500:
        message = '服务器错误(500)'
        break
      case 501:
        message = '服务未实现(501)'
        break
      case 502:
        message = '网络错误(502)'
        break
      case 503:
        message = '服务不可用(503)'
        break
      case 504:
        message = '网络超时(504)'
        break
      case 505:
        message = 'HTTP版本不受支持(505)'
        break
      default:
        message = `连接出错(${status})!`
    }
    return `${message}，请检查网络或联系管理员！`
} 
//let baseURL = "http://192.168.1.61:8082/"
//let baseURL = "http://120.27.226.233"
let baseURL = "https://m.track.hao3you.com"
console.log("env:::",process.env.VUE_APP_MODE)
if(process.env.VUE_APP_MODE == 'development'){
    //开发环境下的执行操作
   // baseURL = ""
}
if(process.env.VUE_APP_MODE == 'test'){
    //测试环境下的执行操作
   // baseURL = "https://www.hao3you.com/"
}
if(process.env.VUE_APP_MODE=="production"){
    //生产环境下的执行操作
   // baseURL = "https://www.hao3you.com/"
}
console.log("baseURL",baseURL)
axios.defaults.baseURL =  baseURL
const service1 = axios.create({
    headers: {
        get: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
          // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
        },
        post: {
          'Content-Type': 'application/json;charset=utf-8'
          // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
        }
    },
    // 跨域请求时是否需要使用凭证
    withCredentials: false,
    // 请求 30s 超时
    timeout: 30000,
   
    // 在向服务器发送请求前，序列化请求数据
    transformRequest: [function (data) {
        data = JSON.stringify(data)
        return data
    }],
    validateStatus () {
        // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
        return true
      },
    // 在传递给 then/catch 前，修改响应数据
    transformResponse: [function (data) {
        if (typeof data === 'string' && data.startsWith('{')) {
            data = JSON.parse(data)
        }
        return data
    }]
})
// 请求拦截器
service1.interceptors.request.use((config) => {
  config.headers['authorization'] = localStorage.getItem('token') ? localStorage.getItem('token') :''
    return config
}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    error.data.msg = '服务器异常，请联系管理员！'
    return Promise.resolve(error)
})

// 响应拦截器
service1.interceptors.response.use((response) => {
    const status = response.status
    let msg = ''
    console.log('响应拦截器',response)
    if (status < 200 || status >= 300) {
        // 处理http错误，抛到业务代码
        msg = showStatus(status)
        if (typeof response.data === 'string') {
            response.data = {msg}
        } else {
            response.data.msg = msg
        }
    }
    if(status ==200 && response.data.code ==-1){
      let num = Math.floor(Math.random()*1000000);
      console.log("num::",num)
      router.push("/index?showLogin="+num)
      return Promise.reject(response.data.message)

    }
    
    return response
}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'
    return Promise.resolve(error)
})


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params){    
  if(process.env.VUE_APP_MODE=="production"){
    url = url.replace("/agent",'')
  }
    return new Promise((resolve, reject) =>{        
        service1.get(url, {            
            params: params        
        }).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)        
    })    
});}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * post方法：原理同get基本一样，但是要注意的是，post方法必须要使用对提交从参数对象进行序列化的操作，所以这里我们通过node的qs模块来序列化我们的参数。这个很重要，如果没有序列化操作，后台是拿不到你提交的数据的。这就是文章开头我们import QS from 'qs';的原因。如果不明白序列化是什么意思的，就百度一下吧，答案一大堆。 
 */

