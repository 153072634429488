import {get } from "./http.js"
import {post } from "./httpPost.js"

export function  getNews(){
    return get("/agent/api/pub/news/good?type=1")
}
export const shipList = (data)=>{
    return get("track/searchShip",data)
}
export const shipDetail = (data)=>{
    return get("track/shipsAissta",data)
}
export const shipAsc = (data)=>{
    return get("/track/positionLatest",data)
}


export const histroyLine = (data)=>{
    return get("track/positionHistory",data)
}
//track/portHistory?shipId=7476
export const histroyShip = (data)=>{
    return get("track/portHistory",data)
}

export const sendCode = (data)=>{
    return get("/track/code",data)
}

export const login = (data)=>{
    return get("/track/login",data)
}

export const attention = (data)=>{
    return post("/track/attention",data)
}

export const attentionList = (data)=>{
    return get("/track/attention/list",data)
}


export const shipPoints = ()=>{
    return get("/track/ship/points")
}

export const portInfo = (data)=>{
    return get("/track/port/statis",data)
}
export const delAttention = (data)=>{
    return get("track/attention/del",data)
}

