<template>
    <div id="app"> 
       
             
        <router-view />
    </div>
</template>
<script>
import _ from "lodash";

import { shipList } from "@/request/api.js";
export default {
    data() {
        return {
            menuList: [
                { key: "1", title: "首    页", to: "/index" },
                { key: "2", title: "产品中心", to: "/product" },
                { key: "3", title: "数字航运", to: "/number" },
                { key: "4", title: "新闻资讯", to: "/news" },
                { key: "5", title: "招贤纳士", to: "/recruit" },
                { key: "6", title: "关于我们", to: "/aboutus" },
            ],
            selectedKeys: ["1"],
            menuShow: false,
            searchStr:'',
            shipList:[]
        };
    },
    created() {
        console.log("==", this.$route);
         this.debounceGetShipList = _.debounce(this.getShipList, 500);
    },
    
    mounted() {
        console.log("==", this.$route);
    },
    watch: {
        "$route.path": {
            immediate: true,
            handler() {
                let path = this.$route.path;
                console.log("pathss::", path);
                let selectedKeys = [];
                this.menuList.forEach((item) => {
                    if (item.to == path) {
                        selectedKeys = [item.key];
                    }
                });
                this.selectedKeys = selectedKeys;
                this.menuShow = false;
            },
        },
         searchStr: function (newV, oldV) {
           // this.debounceGetShipList();
           console.log(newV,oldV)
            this.getShipList()
        },
    },

    methods: {
        handleMenu(obj) {
            let keypath = obj.keyPath;
            let to;
            this.menuList.forEach((item) => {
                if (item.key == keypath) {
                    to = item;
                }
            });
            //  this.selectedKeys = [to.key]
            this.$router.push(to.to);
        },
        handleMenuShow() {
            console.log("menu");
            this.menuShow = !this.menuShow;
        },
        getShipList() {
            shipList({ searchStr: this.searchStr }).then((res) => {
                this.shipList = JSON.parse(res.data);
                console.log(this.shipList);
            });
        },
        clickShipItem(){}
    },
};
</script>
<style lang="scss">
#appbak {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
#app {
    width: 100%;
    height: 100%;
}
.nav {
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    z-index: 10;
}
.nav .logo {
    width: 35px;
    position: absolute;
    left: 10px;
    top: 9px;
    border: 0px solid red;
}
.nav .top_right {
    width: 25px;
    position: absolute;
    right: 10px;
    top: 13px;
    display: block;
}
.ant-dropdown-menu.ant-dropdown-menu.ant-dropdown-content {
    background: #070c37 !important;
    color: #fff !important;
    width: 140px;
    text-align: center;
    letter-spacing: 1px;
}
.ant-dropdown-menu-item > a {
    color: #ddd !important;
}
.ant-dropdown-menu-item > a > .router-active {
    color: #fff !important;
    background: #333;
}

.nav .headerImg {
    display: block;
    width: 100%;
}

 

.navBtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 50px;
    z-index: 1000000;
    border: 1px solid transparent;
    color: #fff;
}
.router-active {
    background: #666;
    color: #fff;
}

.menuList {
    position: absolute;
    right: 0;
    top: 50px;
    text-align: center;
    color: #fff;
    box-shadow: 0 0 5px #ddd;
    font-size: 14px;
}
.menuList > li > div {
    width: 100px;
    height: 25px;
    line-height: 25px;
    background: #2b334e;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.search{width:250px!important;position:fixed;left:50px;top:-3px;}
.shiplist{width:290px;left:50px;top:55px;position:fixed;border:0px solid #ccc; background:rgba(0,0,0,0.3);z-index:1000;font-size:12px;}
.shipItem{border-bottom:1px solid #999;line-height:24px}
</style>
