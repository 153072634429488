import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/index'},
  {
    path: "/index",
    name: "Home",
    meta:{
      title:"首页"
    }, 
    component: () =>
    import(/* webpackChunkName: "index" */ "../views/index.vue")
  },
  
 

 
];

const router = new VueRouter({
  routes
});

export default router;

